<template>
  <Banner bg-image="blue-bg.jpg" talk-img="additional-coping-strategies.png">
    <template v-slot:title>
      How to help someone cope with suicidal thoughts</template
    >
    <template v-slot:subtitle>
      People often have a lot of questions about suicide. Learn the warning
      signs, risk factors, and how to prevent suicide from happening to you or
      someone you know.
    </template>
    <template v-slot:description
      >Try some of these coping strategies with the person you care about.
    </template>
  </Banner>

  <section class="my-8 md:my-14 py-14">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="mb-6 text-2xl font-bold call-text md:text-3xl text-blue">
        Coping strategies for suicidal thoughts, intentions or plans
      </div>
      <p>
        The following strategies for coping with suicidal thoughts are
        suggestions that have resonated with young people we spoke to. It is not
        intended to be an exhaustive list, but it is a good starting point for
        learning new ways to cope.
      </p>
    </div>
  </section>

  <!--  1-5 -->
  <section class="px-2 mx-auto max-w-6xl sm:px-1.5">
    <div class="grid grid-cols-1 gap-6">
      <!-- 1 -->
      <div class="thought-block">
        <div class="no">1</div>

        <div class="items-end justify-between p-4 md:flex lg:p-6 gap-x-8">
          <div class="w-full md:w-4/6">
            <div class="mb-2 text-xl font-bold">
              Assist them in naming their emotions
            </div>
            <p>
              (“I’m feeling sad/angry/afraid,” etc.). Remind them that they are
              more than what they’re feeling and that the emotions they’re
              experiencing are temporary and will eventually pass.
            </p>
          </div>
          <div class="w-full md:w-2/6 md:text-right">
            <GreenBtn
              :width-full="true"
              name="NameYourEmotions"
              text="Learn how to name emotions"
            />
          </div>
        </div>
      </div>
      <!-- 2 -->
      <div class="thought-block">
        <div class="no">2</div>

        <div class="items-end justify-between p-4 md:flex lg:p-6 gap-x-8">
          <div class="w-full md:w-4/6">
            <div class="mb-2 text-xl font-bold">
              Help them find a mindfulness activity
            </div>
            <p>
              Help them notice what’s happening in their body and mind so they
              can learn to reflect on their thoughts without judgment. Exercises
              like guided meditation can help with mindfulness.
            </p>
          </div>
          <div class="w-full md:w-2/6 md:text-right">
            <GreenBtn
              :width-full="true"
              name="PracticeGuidedMeditation"
              text="Try guided meditation"
            />
          </div>
        </div>
      </div>
      <!-- 3 -->
      <div class="thought-block">
        <div class="no">3</div>

        <div class="items-end justify-between p-4 md:flex lg:p-6 gap-x-8">
          <div class="w-full md:w-4/6">
            <div class="mb-2 text-xl font-bold">
              Assist them with grounding techniques
            </div>
            <p>
              Grounding techniques can help your friend get out of their head
              and into their body so they can just be in the moment.
            </p>
          </div>
          <div class="w-full md:w-2/6 md:text-right">
            <GreenBtn
              :width-full="true"
              name="PracticeGroundingTechniques"
              text="Learn grounding techniques"
            />
          </div>
        </div>
      </div>
      <!-- 4 -->
      <div class="thought-block">
        <div class="no">4</div>

        <div class="items-end justify-between p-4 md:flex lg:p-6 gap-x-8">
          <div class="w-full md:w-4/6">
            <div class="mb-2 text-xl font-bold">
              Assist them with developing a safety plan
            </div>
            <p>
              Work together to create a safety plan to help them cope and find
              support when they are feeling overwhelmed.
            </p>
          </div>
          <div class="w-full md:w-2/6 md:text-right">
            <GreenBtn
              :width-full="true"
              name="CreateASafetyPlan"
              text="Create a safety plan"
            />
          </div>
        </div>
      </div>
      <!-- 5 -->
      <div class="thought-block">
        <div class="no">5</div>

        <div class="items-end justify-between p-4 md:flex lg:p-6 gap-x-8">
          <div class="w-full md:w-4/6">
            <div class="mb-2 text-xl font-bold">
              Connect them with an elder or a professional
            </div>
            <p>
              Help them get in touch with an elder or a professional for
              additional support and coping strategies.
            </p>
          </div>
          <div class="w-full md:w-2/6 md:text-right">
            <GreenBtn
              :width-full="true"
              name="FindSomeoneToTalkTo"
              text="Find someone to talk to"
            />
          </div>
        </div>
      </div>
      <!--  -->
    </div>
  </section>

  <FooterCard
    img="someone-else-coping-coffee.png"
    text="Find someone to talk to"
    name="FindSomeoneToTalkTo"
  >
    <template v-slot:header>Take care of yourself</template>
    <template v-slot:content>
      Trying to support and understand why someone is considering ending their
      life can be very challenging. You may find it helpful to discuss your
      feelings with another friend, or a confidential service. If you need help
      supporting someone who is having suicidal thoughts. You can call the
      Healing Lodge at Miskanawah or talk to a professional who can guide you.
    </template>
  </FooterCard>
</template>

<script>
import Banner from "@/components/Banner.vue";
import GreenBtn from "@/components/GreenBtn.vue";
import FooterCard from "@/components/FooterCard.vue";
export default {
  name: "AdditionalCopingStrategies",
  components: {
    Banner,
    GreenBtn,
    FooterCard,
  },
};
</script>

<style scoped>
@media (max-width: 640px) {
  .thought-block {
    display: flex !important;
    flex-direction: column !important;
    padding-left: 0 !important;
    margin-bottom: 2rem !important;
  }
  .thought-block .no {
    position: relative !important;
    justify-content: center !important;
    transform: translate(0, -50%) !important;
  }
}
.thought-block {
  display: flex;
  align-items: center;
  border: 5px solid #3c5eaa;
  border-radius: 10px;
  background-color: #f8f8f8;
  padding-left: 130px;
  position: relative;
}
.thought-block .no {
  background: #3c5eaa;
  color: #fff;
  font-weight: bold;
  font-size: 60px;
  width: 120px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -2px;
  left: 0;
  bottom: 0;
}
</style>
